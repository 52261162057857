import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "views",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#views",
        "aria-label": "views permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Views`}</h1>
    <h3 {...{
      "id": "sdkrendercreatetransactionpayload-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sdkrendercreatetransactionpayload-options",
        "aria-label": "sdkrendercreatetransactionpayload options permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`sdk.renderCreateTransaction(payload, options)`}</h3>
    <h4 {...{
      "id": "payload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#payload",
        "aria-label": "payload permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`payload`}</h4>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`Object`}</inlineCode>{` | required`}</p>
    </blockquote>
    <p>{`With the `}<inlineCode parentName="p">{`payload`}</inlineCode>{` parameter, you are able to pre-populate SDK fields. All attributes in the payload are optional (however, attributes marked with a `}<inlineCode parentName="p">{`!`}</inlineCode>{` are required) - so you can pre-populate what you wish.`}</p>
    <p><a parentName="p" {...{
        "href": "/sdk/transaction/prepopulating/"
      }}>{`Click here`}</a>{` for guidance on how to pre-populate transaction attributes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  /* ====== START: VIRTUAL TERMINAL PRE-POPULATION ATTRIBUTES ====== */
  platform: "virtual-terminal",
  providerNumber: string,
  invoiceReference: string,
  chargeAmount: string,
  paymentMethod: "new-payment-card" | "phone",
  patient: {
    mobile: string
  },
  /* ====== END: VIRTUAL TERMINAL PRE-POPULATION ATTRIBUTES ====== */

  /* ====== START: GENERAL FUNDER PRE-POPULATION ATTRIBUTES ====== */
  funder: "comcare" | "dva" | "ghs" | "hicaps" | "icare | "medicare" | "patient-funded", 

  providerNumber: string,

  invoiceReference: string,

  memberId: string, // Medipass Member Account ID
  // OR
  patientId: string, // Medipass Business Patient ID
  // OR
  patient: {
    refId: string,
    firstName: string,
    lastName: string,
    mobile: string,
    dob: string, // "YYYY-MM-DD" format
    accountNumber: string, // Medicare card number / PHI card number / icare claim number
    reference: string // Medicare card reference / PHI card rank,
    isConcessionHolder: boolean // For Medicare only
    sex: 'M' | 'F' | 'O' | undefined
  },

  claimableItems: [{
    itemCode!: string,
    serviceDateString: string, // "YYYY-MM-DD" format
    price: string, // E.g. "$40.00"
    isGross: boolean, // Is the price the gross amount? Defaults to \`false\` (net amount).
    quantity: number,
    isTaxable: boolean,
    extras: {
      duration: string,
      treatmentLocation: string,
      secondDevice: string,
      distanceTravelled: string
    }
  }],

  nonClaimableItems: [{
    description!: string,
    price: string,
    isGross: boolean
  }],

  webhooks: [{
    url!: string,
    event!:
      'invoiceBalancePaid' |
      'invoiceCancelled' |
      'invoiceCompleted' |
      'healthFundApprovedInvoice' |
      'healthFundRejectedInvoice',
    method!: 'GET' | 'PUT' | 'POST' | 'DELETE',
    headers: { [key: string]: string }
  }],
  /* ====== END: GENERAL PRE-POPULATION ATTRIBUTES ====== */

  /* ====== START: FUNDER-SPECIFIC PRE-POPULATION ATTRIBUTES ====== */
  funderData: {
    hicaps: {
      patient: {
        healthFundCode: string
      }
    },
    medicare: {
      isBulkBilled: boolean,

      claimantMemberId: string, // Medipass Member Account ID
      // OR
      claimantMemberId: string, // Medipass Business Patient ID
      // OR
      claimant: {
        firstName: string,
        lastName: string,
        mobile: string,
        dob: string, // "YYYY-MM-DD" format
        accountNumber: string, // Medicare card number / PHI card number
        reference: string // Medicare card reference / PHI card rank
      },

      referral: {
        providerNumber: string,
        providerName: string,
        referrerType: 'gp' | 'specialist',
        issueDateString: string,
        period: 'standard' | 'non-standard' | 'indefinite',
        periodDescription: number
      },
      nonReferral: {
        reason: 'hospital' | 'lost' | 'emergency' | 'not-required' | 'remote-exemption',
        description: string
      }
    },
    dva: {
      referral: {
        providerNumber: string,
        providerName: string,
        referrerType: 'gp' | 'specialist',
        issueDateString: string,
        period: 'standard' | 'non-standard' | 'indefinite',
        periodDescription: number
      },
      nonReferral: {
        reason: 'hospital' | 'lost' | 'emergency' | 'not-required' | 'remote-exemption',
        description: string
      }
    },
    icare: {
      approvalNumber: string,
      serviceNotes: string
    }
    comcare: {
      serviceNotes: string
    }
  }
  /* ====== END: FUNDER-SPECIFIC PRE-POPULATION ATTRIBUTES ====== */
}
`}</code></pre>
    <h4 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#options",
        "aria-label": "options permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`options`}</h4>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`Object`}</inlineCode></p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  // Version of the Medipass Transaction API. Defaults to '2'.
  // NOTE: Version '2' will be deprecated in the coming months. We highly recommend setting this value to '3'.
  version: '2' | '3',

  // Invoked when the transaction (and patient payment - if required) has been submitted & processed successfully.
  onSuccess: function(transaction) {},

  // Invoked when the transaction submission failed.
  onError: function(error) {},

  // Invoked when the 'Cancel' button is clicked.
  onCancel: function() {},

  // Invoked when the transaction has been created.
  onTransactionCreated: function(transaction) {},

  // Invoked when the transaction has been updated.
  onTransactionUpdated: function(transaction) {},

  // Invoked when the modal closes (either explicitly via the close (X) button or after a transaction is completed).
  onCloseModal: function({ error, transaction, status: 'success' | 'error' | 'cancelled' | undefined }) {},

  // ID of the HTML element to contain the Medipass frame in.
  containerId: string,

  // Hide the help desk chat bubble on SDK launch.
  hideChatBubble: boolean,

  // Optional config overrides - if not set, will use global config from sdk.setConfig().
  env: 'stg' | 'prod',
  apiKey: string,
  appId: string,
  appVersion: string
}
`}</code></pre>
    <h4 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h4>
    <h5 {...{
      "id": "virtual-terminal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#virtual-terminal",
        "aria-label": "virtual terminal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Virtual terminal`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction({
  platform: 'virtual-terminal'
  providerNumber: '12345678',
  paymentMethod: 'phone',
  patient: {
    mobile: '0430123456'
  }
}, {
  onSuccess: function (transaction) {
    alert(\`Great success! Your transaction ID is \${transaction._id}\`);
  },
  onError: function (error) {
    alert(\`Mamma mia! You got yourself into a pickle! \${error.message}\`);
  },
  onCancel: function () {
    alert('Transaction cancelled.');
  }
})
`}</code></pre>
    <h5 {...{
      "id": "funder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#funder",
        "aria-label": "funder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Funder`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction({
  funder: 'hicaps'
  providerNumber: '12345678',
  memberId: '123'
}, {
  onSuccess: function (transaction) {
    alert(\`Great success! Your transaction ID is \${transaction._id}\`);
  },
  onError: function (error) {
    alert(\`Mamma mia! You got yourself into a pickle! \${error.message}\`);
  },
  onCancel: function () {
    alert('Transaction cancelled.');
  }
})
`}</code></pre>
    <h3 {...{
      "id": "sdkrenderauthorizepaymentpayload-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sdkrenderauthorizepaymentpayload-options",
        "aria-label": "sdkrenderauthorizepaymentpayload options permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`sdk.renderAuthorizePayment(payload, options)`}</h3>
    <h4 {...{
      "id": "payload-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#payload-1",
        "aria-label": "payload 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`payload`}</h4>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`Object`}</inlineCode>{` | required`}</p>
    </blockquote>
    <p>{`With the `}<inlineCode parentName="p">{`payload`}</inlineCode>{` parameter, you are able to pre-populate SDK fields. All attributes in the payload are optional.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  // Note: Only "medicare" is supported at the moment.
  funders: Array<'medicare'>,

  patient: {
    firstName: string,
    lastName: string,
    mobile: string,
    dob: string, // "YYYY-MM-DD" format
    accountNumber: string, // Medicare card number
    reference: string // Medicare card reference
  }
}
`}</code></pre>
    <h4 {...{
      "id": "options-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#options-1",
        "aria-label": "options 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`options`}</h4>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`Object`}</inlineCode></p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  // Self checkout session token created with the Medipass API. It is required.
  token!: string,

  // ID of the HTML element to contain the Medipass frame in. It is required.
  containerId!: string,

  // If you are not bringing your own submit button, we can render one for you.
  showSubmitButton: boolean,

  // Width of the embedded frame
  width: string,

  // Invoked when the payment authorization has submitted successfully.
  onSuccess: function(selfCheckoutSession) {},
  // Invoked when the payment authorization submission failed.
  onError: function(error) {},
  // Invoked when the form state has changed (on form initialization & every update).
  onFormStateChanged: function(form) {},
  // Invoked when the screen state has changed.
  onScreenStateChanged: function (screen) {},

  // Optional config overrides - if not set, will use global config from sdk.setConfig().
  env: 'stg' | 'prod',
  appId: string,
  appVersion: string
}
`}</code></pre>
    <h4 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderAuthorizePayment({
  funders: ['medicare']
}, {
  token: '123abc',
  containerId: 'authorize-payment',
  width: '400px',

  onSuccess: function (selfCheckoutSession) {
    alert(\`Great success!\`);
  },
  onError: function (error) {
    alert(\`Mamma mia! You got yourself into a pickle! \${error.message}\`);
  }
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      